import { styled } from 'styled-components'

const HomePageContainer = styled.div`
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #d2d2c8;
  flex-direction: column;
  align-items: center;
  justify-content:space-between;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Logo = styled.img`
  width: 100vw;
  mix-blend-mode: multiply;
  z-index: 1;
  position: absolute;
  bottom: 0;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const Heading = styled.h1`
  text-shadow: 0px 0px 0.5px black;
  font-size: 16px;
  transform: scaleY(0.9);
  font-weight: 400;
  text-align: center;
  width: 50%;
  margin-top: 15vh;
  z-index: 12;
  cursor: default;
  text-transform: uppercase;

  &:hover + ${Logo} {
    opacity: 0.1;
  }

  @media (max-width: 768px) {
    width: 90%;
    margin: 0;
    margin-bottom: 70px;
  }
`;

const HomePage = () => {
  return (
    <HomePageContainer>
      <Heading>
        We are a cross-disciplinary cultural producer oriented to art, communication and experimentation through rizomatic events, installations, exhibitions and creative campaings
      </Heading>
        <Logo src="images/pont_logo.gif"></Logo>
    </HomePageContainer>
  )
}

export default HomePage
