import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from 'swiper';
import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import Project from './Project.js';
import { useSelector } from 'react-redux';

const SwiperGallery = () => {
  const projects = useSelector((state) => state.projects.projects);

  SwiperCore.use([]);
  return (
    <Swiper
      slidesPerView={"auto"}
      className="mySwiper"
      spaceBetween={30}
      freeMode={true}
      mousewheel={{thresholdTime: 500}}
      modules={[Mousewheel, FreeMode, Pagination]}
    >
      { projects && projects.map((project) => (
        <SwiperSlide key={project.id} className={`project${project.id}`}>
          <Project project={project}></Project>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default SwiperGallery