import { useSelector } from 'react-redux';
import { styled } from 'styled-components';

const ProjectPageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.$bgcolor};
  overflow: hidden;
  padding-top: 7%;
  margin-left: 60px;
  margin-right: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow: scroll;
    align-items: center;
    height: calc(100vh - 70px);
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 63%;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  gap: 40px;
  margin-left: 20px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  /* Webkit (Safari/Chrome) scrollbar customization */
  &::-webkit-scrollbar {
    width: 6px; /* Adjust the width as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Hide thumb */
  }

  @media (max-width: 768px) {
    width: calc( 100% - 40px );
    margin-left: 0px;
    margin-right: 0px;
    overflow-y: scroll;
  }
`;

const Image = styled.img`
  width: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  color: ${props => props.$textcolor};
  text-transform: uppercase;
  margin-right: 20px;

  h1, h2, p {
    margin: 0;
    padding: 0;
    text-shadow: 0px 0px 0.5px black;
    font-size: 16px;
    transform: scaleY(0.9);
    font-weight: 400;
  }

  p {
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    width: calc( 100% - 40px );
    margin-right: 0px;
    margin-bottom: 20px;
  }
`

const ProjectPage = () => {
  const isMobile = window.innerWidth <= 768;
  const projectToShow = useSelector((state) => state.projectShowing.project);
  return (
    <ProjectPageContainer $bgcolor={projectToShow.backgroundColor}>
      {!isMobile ? <><ImagesContainer>
        {projectToShow && projectToShow.images?.map((image) => {
          return (
            <Image src={`${image}`}></Image>
          )
        })}
      </ImagesContainer> 
      <TextContainer $textcolor={projectToShow.textColor}>
        <h1>{projectToShow.title}</h1>
        <h2>{projectToShow.date}</h2>
        <h2>CLIENT: {projectToShow.client}</h2>
        <h2>ARTISTS: {projectToShow.artists}</h2>
        <p>{projectToShow.description}</p>
      </TextContainer> </>: 
      <>
        <TextContainer $textcolor={projectToShow.textColor}>
          <h1>{projectToShow.title}</h1>
          <h2>{projectToShow.date}</h2>
          <h2>{projectToShow.client}</h2>
          <p>{projectToShow.description}</p>
        </TextContainer>
        
          {projectToShow && projectToShow.images?.map((image) => {
            return (
              <Image src={`${image}`}></Image>
            )
          })}
         </>}
    </ProjectPageContainer>
  );
};

export default ProjectPage;
