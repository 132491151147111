import { configureStore } from '@reduxjs/toolkit'

import ProjectShowingSliceReducer from './ProjectShowingSlice'
import ProjectsSliceReducer from './ProjectsSlice'

export const store = configureStore({
  reducer: {
    projectShowing: ProjectShowingSliceReducer,
    projects: ProjectsSliceReducer
  },
})