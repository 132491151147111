import { createSlice } from "@reduxjs/toolkit";

const ProjectsSlice = createSlice({
  name: 'projectShowing',
  initialState: {
    projects: []
  },
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    }
  }
});

export const { setProjects: setProjectsActionCreatorg } = ProjectsSlice.actions;

export default ProjectsSlice.reducer;
