import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomePage from './pages/HomePage/HomePage.js';
import { styled } from 'styled-components';
import ProjectsPage from './pages/ProjectsPage/ProjectsPage.js';
import NavBar from './components/NavBar/NavBar.js';
import ProjectPage from './pages/ProjectPage/ProjectPage.js';
import AboutPage from './pages/AboutPage/AboutPage.js';
import ContactPage from './pages/ContactPage/ContactPage.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setProjectsActionCreatorg } from './redux/ProjectsSlice.js';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  height: ${(props) => props.$device === "iOS" ? "-moz-available" : "100vh"};
  height: ${(props) => props.$device === "iOS" ? "-webkit-fill-available" : "100vh"};
  height: ${(props) => props.$device === "iOS" ? "fill-available" : "100vh"};
  min-height: ${(props) => props.$device === "iOS" ? "-webkit-fill-available" : "none"};
  background-color: ${props => props.$backColor};
  overflow: hidden;
`;

function App() {

  const dispatch = useDispatch();
  const route = useLocation().pathname
  const [backgroundColor, setBackgroundColor] = useState("#e8e4dc")
  const projectToShow = useSelector((state) => state.projectShowing.project);

  const [mobileOS, setMobileOS] = useState(null);
  const [textShown, setTextShown] = useState(false);

  useEffect(()=>{
    if (textShown) {
      console.log('CODE DEVELOPED BY ALEJANDRO MACHIN')
    }
      setTextShown(true)
  }, [textShown])
  
  useEffect(() => {
    setMobileOS(getMobileOperatingSystem())
  }, [])

  function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor ||window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
  }

  const formatProject = (project) => {

    return {...project.attributes,
      id: project.id,
      images: Object.values(project.attributes.images)
    }
  }

  useEffect(() => {
    const fetchingData = async () => {
      
      await axios.get(`${process.env.REACT_APP_API_URL}projects`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}` 
          }
        }
      )
      .then((response) => {
        if(response.data){
          const beautifiedData = response.data.data?.map((project) => formatProject(project));
          dispatch(setProjectsActionCreatorg(beautifiedData));
        }
      })
    }
    fetchingData();
  }, [dispatch])

  useEffect(() => {
    if(route === "/projects"){
      setBackgroundColor("#e8e4dc") 
    } else if (route === "/about") {
      setBackgroundColor("#086cfc")
    } else if (route === "/contact") {
      setBackgroundColor("#587564")
    } else if (route === "/home") {
      setBackgroundColor("#d2d2c8")
    } else if (route.includes("/projects/")) {
      setBackgroundColor(projectToShow?.backgroundColor)
    }
  }, [route, projectToShow])

  return (
    <AppContainer $device={mobileOS} $backColor={backgroundColor}>

      <NavBar/>

      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} exact />
        <Route path="/projects/:name" element={<ProjectPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </AppContainer>

  );
}

export default App;
