import { styled } from 'styled-components';
import SwiperGallery from '../../components/Swiper/SwiperGallery.js';
import Project from '../../components/Swiper/Project.js';
import { useSelector } from 'react-redux';

const ProjectsPageContainer = styled.div`
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #e8e4dc;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: 768px) {
    overflow: scroll;
    height: calc(100vh - 70px);
  }
`

const ProjectsPage = () => {

  const projects = useSelector((state) => state.projects.projects);
  const isMobile = window.innerWidth <= 768;

  return (
    <ProjectsPageContainer>
      {!isMobile && <SwiperGallery/> }
      { isMobile && projects && projects.map((project) => (
          <Project project={project}></Project>
      ))}
    </ProjectsPageContainer>
  );
}

export default ProjectsPage;
