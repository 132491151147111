import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components"

const NavBarContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 400;
  text-shadow: 0px 0px 0.5px black;
  z-index: 3;
  position: fixed;

  @media (max-width: 768px) {
    width: 90vw;
    position: sticky;
    min-height: 70px;
    justify-content: ${props => props.$isHomePage ? 'center' : 'space-between'};
    transition: all  0.3s ease; /* Add the transition */
  }
`;

const NavBarRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const NavBarElement = styled.div`
  display: flex;
  margin: 0 20px;
  cursor: pointer;
  transform: scaleY(0.9);

  @media (min-width: 768px) {
    &:hover {
      color: ${props => handleHover(props.type)};
      text-shadow:0px 0px 0.5px ${props => handleHover(props.type)};
    }
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const Logo = styled.img`
  width: 150px;
  position: absolute;
  left: calc(50% - 75px);
  mix-blend-mode: multiply;

  @media (max-width: 768px) {
    width: 100px;
    margin-left: -20px;
    right: -5vw;
    left: auto;
  }
`;

const handleHover = (type) => {
  switch (type) {
    case "projects":
      return "#fa7143"
    case "about":
      return "#3786f7"
    case "contact":
      return "#6d8471"
    default:
      return "#000"
  }
}

const NavBar = () => {
  const [logoChosenPath, setLogoChosenPath] = useState("")
  const route = useLocation().pathname
  const isHome = route === "/home"
  const isMobile = window.innerWidth < 768;
  const navigate = useNavigate();

  function handleClick(route) {
    navigate(route);
  }

  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  useEffect(() => {
    const randomNumber = getRandomNumber(1, 31)
    setLogoChosenPath(`/images/logotipos/logotipo_${randomNumber}.png`)
  }, [route])

  function projectClass () {
    if(route === "/projects"){
      return "actualPageProjects"
    } else if (route.includes("/projects/")) {
      return "actualPage"
    }
      return null
  }



  return (
    <NavBarContainer $isHomePage={isHome}>
    { !isMobile && <NavBarElement className={projectClass()} onClick={() =>handleClick("/projects")} type="projects">PROJECTS</NavBarElement> }
      { !isMobile && !isHome && logoChosenPath !== "" && <Logo onClick={() =>handleClick("/home")} src={logoChosenPath}></Logo> }

      <NavBarRight>
        { isMobile && <NavBarElement className={projectClass()} onClick={() =>handleClick("/projects")} type="projects">PROJECTS</NavBarElement>}
        <NavBarElement className={route === "/about" ? "actualPage" : null} onClick={() =>handleClick("/about")} type="about">ABOUT</NavBarElement>
        <NavBarElement className={route === "/contact" ? "actualPage" : null} onClick={() =>handleClick("/contact")} type="contact">CONTACT</NavBarElement>
        { isMobile && !isHome && logoChosenPath !== "" && <Logo onClick={() =>handleClick("/home")} src={logoChosenPath}></Logo> }

      </NavBarRight>
    </NavBarContainer>
  )
}

export default NavBar
