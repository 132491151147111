import { styled } from 'styled-components';

const ContactPageContainer = styled.div`
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #587564;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    gap: 20px;
    height: calc(100vh - 70px);
  }
`;

  const Text = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 46px;
    text-shadow: 0px 0px 1px #cec5b5;
    line-height: 1;
    z-index: 1;
    a, p {
      color: #cec5b5;
      margin: 0;
      padding: 0;
      font-weight: 400;
    }

    @media (max-width: 768px) {
      font-size: 26px;
    }

  `;
  const Illustration2 = styled.img`
    position: absolute;
    top: 15vh;
    left: 2vw;
    width: 40vw;
    mix-blend-mode: multiply;

    z-index: 2;

    @media (min-width: 1508px) {
      width: ${props => "40vw" || 'auto'};
      left: 12vw;
    }

    @media (max-width: 768px) {
      top:  20vh;
      left: 25vw;
      width: 60vw;
    }
  `;

   const Illustration1 = styled.img`
    position: absolute;
    top: 0vh;
    left: -5vw;
    width: 40vw;
    mix-blend-mode: multiply;

    z-index: 2;

    @media (min-width: 1508px) {
      width: 40vw;
      left: 5vw;
    }

    @media (max-width: 768px) {
      top:  15vh;
      left: 0vw;
      width: 60vw;
    }
  `;

const ContactPage = () => {
  return (
    <ContactPageContainer>

      <Text>
        <a target='_blank' href='mailto:hello@ppoonntt.com' rel="noreferrer">HELLO@PPOONNTT.COM</a>
        <p>+34 678 789 252</p>
      </Text>
      <Text>
        <a target='_blank' href="https://instagram.com/ppoonnttt" rel="noreferrer">@PPOONNTTT</a>
        <p>WWW.PPOONNTT.COM</p>
      </Text>
      <Illustration1 src="/images/illustrations/Grafit_Alejandro.webp">
      </Illustration1>
      <Illustration2 src="/images/illustrations/Grafit_Bea.webp">
      </Illustration2>

    </ContactPageContainer>
  );
}

export default ContactPage;
