import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { setProjectShowingActionCreator } from '../../redux/ProjectShowingSlice.js';

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  cursor: pointer;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  z-index: 0;
    margin: 0; /* Add this */
  padding: 0; /* Add this */
`;

const RedImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ff4503;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  mix-blend-mode: hard-light;
  opacity: 0;
  transition: opacity 0.3s;

  ${ProjectContainer}:hover & {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  margin: 0; /* Add this */
  padding: 0; /* Add this */

  box-sizing: border-box;
  display: flex;
`;

const ProjectContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  min-height: 50px;
  height: 70px;
  gap: 40px;
  margin: 15px 0 10px 0;
  text-shadow: 0px 0px 0.5px black;
  font-size: 16px;
  transform: scaleY(0.9);
  font-weight: 400;

  @media (max-width: 768px) {
    margin: 20px;
    justify-content: space-between;
  }
`;

const ProjectTitle = styled.p`
  white-space: pre-wrap;
  margin: 0;
`;

const ProjectDate = styled.p`
  margin: 0;
`;

const ProjectTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Project = ({ project }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 768;
  console.log(project)
  useEffect(() => {
    if (!isMobile && project?.width) {
      document.querySelector(`.swiper-slide.project${project.id}`).style.width =
        project.width;
    }
  });

  const projectNameForURL = project.title.replaceAll(" ", "-").toLowerCase()
  
  const navigateToProject = (id) => {
    dispatch(setProjectShowingActionCreator(project));
    navigate(`/projects/${projectNameForURL}`);
  };

  return (
    <ProjectContainer onClick={() => navigateToProject(project.id)}>
      <ImageContainer>
        <ProjectImage src={`${project?.image}`}></ProjectImage>
        <RedImage></RedImage>
      </ImageContainer>
      <ProjectContent>
        <ProjectDate>{project?.date}</ProjectDate>
        <ProjectTitleContainer>
          <ProjectTitle>{project?.title}</ProjectTitle>
          <ProjectTitle>{project?.client}</ProjectTitle>
        </ProjectTitleContainer>
      </ProjectContent>
    </ProjectContainer>
  );
};

export default Project;
