import { styled } from 'styled-components';

const AboutPageContainer = styled.div`
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #086cfc;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  text-transform: uppercase;

  @media (max-width: 768px) {
    overflow: scroll;
    height: calc(100vh - 70px);
  }
`

const MainText = styled.p`
  display: flex;
  color: #000;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  font-weight: 400;
  text-shadow: 0px 0px 0.5px black;
  font-size: 16px;
  transform: scaleY(0.9);
  height: fit-content;
  
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 30%;
  justify-content: center;
  align-items: flex-end;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 10px !important;
  gap: 10px;

  p {
    display: block;
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    height: 70%;
    gap: 0;
  }
`;

const Illustrations = styled.div`
  width: 100%;
  height: 70%;

  @media (max-width: 768px) {
    height: 5%;
  }
`;

const Illustration1 = styled.img`
  position: absolute;
  top: 0vh; /* Fixed top value */
  left: 30vw; /* Fixed left value */
  width: 40vw; /* Fixed width value */
  z-index: 1;
  mix-blend-mode: multiply;

  @media (max-width: 768px) {
    top: 10vh;
    left: 10vw; 
    width: 50vw;
  }
`;

const Illustration2 = styled.img`
  position: absolute;
  top: -5vh;
  left: 55vw;
  width: 40vw;
  z-index: 1;
  mix-blend-mode: multiply;

  @media (max-width: 768px) {
    top: 5vh;
    left: 40vw;
    width: 50vw;
  }
`;

const AboutPage = () => {

  return (
    <AboutPageContainer>
      <Illustrations>
        <Illustration1 src="/images/illustrations/Grafit_Carla.webp"></Illustration1>
        <Illustration2 src="/images/illustrations/Grafit_Pol.webp"></Illustration2>
      </Illustrations>
      <BottomContainer>
        <MainText>
          PONT <br></br> is a bridge for connections between art and people. We curate and produce cross-disciplinary art events, installations, exhibitions and campaigns. Art (in any form) is a driving force for social transformation, so we are committed to develop specific collaborative projects that engage with contemporary aesthetics through creative disciplines.
        </MainText>
        <MainText>
          OUR VISION <br></br> Each of our projects is enriched by the interaction and integration of different art fields such as contemporary art, design, illustration, photography, art direction or architecture. From the conceptualisation to the execution, we work from a comprehensive approach aiming to create innovative tailored experiences involving talented artists and creatives.
        </MainText>
      </BottomContainer>
    </AboutPageContainer>
  );
}

export default AboutPage;
